import React from 'react'
import Layout from '../../templates/Main'
import Feed from '../../modules/Feed'
import Heading from '../../modules/Heading'

const RestorersListPage = ({ params }) => {
    return (
        <Layout id={params.id}>
            <Heading size='h1' text='The Restorers' />
            <Feed type='restorers' />
        </Layout>
    )
}

export default RestorersListPage
